/* eslint-disable react/no-unstable-nested-components */
import {
    Alert,
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useQueryClient } from "@tanstack/react-query";
import { IAppCustomDates } from "../../../../utils/types";
import { momentNotTz } from "../../../../utils/moment";
import {
    buildSuccessMessageForMatchedPayments,
    cn,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import { getPaymentStatusTag } from "../../helpers/payments.helpers";
import {
    ISplitPaymentPayload,
    IUnmatchedPayment,
    PaymentStatus,
} from "../../types/payments.types";
import CopyIcon from "../../../../components/Icons/Copy";
import { copyToClipboard } from "../../../../utils/helpers/copy";
import useToast from "../../../../hooks/useToast";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchUnmatchedPayment } from "../../hooks/payments.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import {
    useDeleteUnmatchedPayment,
    useMatchPayment,
    // useMatchPayment,
    useRefreshPaymentStatus,
    useSplitPayment,
} from "../../hooks/payments.mutations";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import { UserPermisions } from "../../../../utils/types/user";
import { getBillStatusTag } from "../../../billing/helpers/billing.helpers";
import { BillStatus } from "../../../billing/types/billing.types";
import { IBillingResponse } from "../../../../utils/types/billing";
import { useFetchInvoices } from "../../../billing/hooks/queries/billing.queries";

interface UnmatchedPaymentProps {
    setTotalUnMatchedCount: React.Dispatch<React.SetStateAction<string>>;
}

// FUNCTION TO BUILD SUCCESS MESSAGE FOR DELETED PAYMENTS
const buildSuccessMessageForDeletedUnMatchedPayments = (
    deletedPayments: string[],
    undeletedPayments: string[]
) => {
    const deletedPaymentsMessage =
        deletedPayments.length > 0
            ? `${deletedPayments.length} Payments deleted`
            : "";
    const undeletedPaymentMessage =
        undeletedPayments.length > 0
            ? `${undeletedPayments.length} Payments were not deleted`
            : "";
    const message = `Update complete: ${[
        deletedPaymentsMessage,
        undeletedPaymentMessage,
    ]
        .filter(Boolean)
        .join(", ")}`;
    return message;
};

// FUNCTION TO BUILD SUCCESS MESSAGE FOR REFRESHED PAYMENTS
const buildSuccessMessageForRefreshPaymentStatus = (
    updatedPayments: string[],
    unUpdatedPayments: string[]
) => {
    const updatedPaymentMessage =
        updatedPayments.length > 0
            ? `${updatedPayments.length} Bill Status updated`
            : "";
    const unUpdatedPaymentMessage =
        unUpdatedPayments.length > 0
            ? `${unUpdatedPayments.length} Bill Status not updated`
            : "";
    const message = `Update complete: ${[
        updatedPaymentMessage,
        unUpdatedPaymentMessage,
    ]
        .filter(Boolean)
        .join(", ")}`;
    return message;
};

const customDates = [
    IAppCustomDates.ALL_TIME,
    IAppCustomDates.LAST_24_HRS,
    IAppCustomDates.THIS_WEEK,
    IAppCustomDates.LAST_WEEK,
    IAppCustomDates.LAST_TWO_WEEKS,
    IAppCustomDates.THIS_MONTH,
    IAppCustomDates.LAST_MONTH,
];

type Dialogs = "breakdown" | "split_payment";

export default function UnmatchedPaymentsList({
    setTotalUnMatchedCount,
}: UnmatchedPaymentProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [rowSelection, setRowSelection] = React.useState({});
    const [breakdownRowSelection, setBreakdownRowSelection] = React.useState(
        {}
    );
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IUnmatchedPayment>[]>();
    const [getSelectedDataBreakdown, setGetSelectedDataBreakdown] =
        React.useState<Row<IBillingResponse>[]>();
    const [dialogInview, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [currentUnmatchedPaymentId, setCurrentUnmatchedPaymentId] =
        React.useState<string | null>(null);
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const clientFilter = searchParams.get("search_string") || "";
    const customDateFilter =
        searchParams.get("custom_date") || IAppCustomDates.LAST_24_HRS;
    const invoiceStatusFilter = searchParams.get("invoice_status") || "";
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedClientFilter = useDebounce(clientFilter);

    // Mutation hook for splitting payment
    const splitPayment = useSplitPayment();

    const { toast } = useToast();

    // check if logged in user can perform actions
    const canPerformActions = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const queryClient = useQueryClient();

    const sortableColumns = [
        "client_name",
        "payment_date",
        "payment_amount",
        "open_bill",
    ];

    // React-hook-form for split payment form
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
    } = useForm<ISplitPaymentPayload>({
        resolver: yupResolver(
            yup.object().shape({
                invoices: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .string()
                            .matches(
                                /^(?!^\.)(\d+(\.\d+)?|\.\d+)$/,
                                "Please enter a valid amount"
                            ),
                    })
                ),
            })
        ),
        mode: "onChange",
    });

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const {
        data,
        isLoading: fetchPaymentLoading,
        isSuccess,
        error,
    } = useFetchUnmatchedPayment({
        search_string: debouncedClientFilter,
        custom_date: customDateFilter,
        invoice_status: invoiceStatusFilter,
        from_date: searchParams.get("from_date")
            ? momentNotTz(searchParams.get("from_date")).toDate()
            : null,
        to_date: searchParams.get("to_date")
            ? momentNotTz(searchParams.get("to_date")).toDate()
            : null,
        page: Number(searchParams.get("page")) || 1,
        limit: LISTS_DEFAULT_LIMIT,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    const paymentInView = data?.data.find(
        (payment) => payment.unmatched_payment_id === currentUnmatchedPaymentId
    );

    const invoicesList = useFetchInvoices(
        {
            client_id: paymentInView?.client_id,
            invoice_status: [BillStatus.OPEN],
        },
        Boolean(paymentInView?.client_id)
    );

    const deleteUnmatchedPayment = useDeleteUnmatchedPayment();
    const refreshPaymentStatus = useRefreshPaymentStatus();
    const { mutate, isLoading: matchPaymentLoading } = useMatchPayment();

    // watch invoices in split payment form
    const invoices = watch("invoices");

    // get the count of unmatched payment based on the current filter
    if (data) {
        setTotalUnMatchedCount(String(data.total_count));
    }
    if (data && !data.total_count) {
        setTotalUnMatchedCount("0");
    }

    const handleRowClick = (row: Row<IUnmatchedPayment>) => {
        setDialogInView("breakdown");
        setCurrentUnmatchedPaymentId(() => row.original.unmatched_payment_id);
    };

    const handleSetClientFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("custom_date", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleInvoiceStatusFilterChange = (status: string) => {
        if (status === "") searchParams.delete("invoice_status");
        else searchParams.set("invoice_status", status);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handlePageFilter = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const getInvoiceFilterTextToDisplay = () => {
        if (invoiceStatusFilter === "has_open_invoice")
            return "Show payments with open bills";
        return "Show payments with no bills";
    };

    const handleCopyTransactionId = (id: string) => {
        copyToClipboard(id);
        toast({
            mode: "success",
            message: "Transaction id copied successfully",
        });
    };

    // Function to run when trying to close the payment invoice breakdown
    const handleCloseBreakdownModal = () => {
        setDialogInView(null);
        setCurrentUnmatchedPaymentId(null);
        setBreakdownRowSelection({});
    };

    const handleBreakdownEnableRowSelection = (row: Row<IBillingResponse>) => {
        return (
            row.original.insurance_amount_collected +
                row.original.coinsurance_amount_collected <
            row.original.insurance_amount + row.original.coinsurance_amount
        );
    };

    const handleDeletePayment = () => {
        const selectedIds = getSelectedData
            ? getSelectedData.map((item) => item.original.unmatched_payment_id)
            : [];

        const deletePaymentPayload = {
            unmatched_payment_ids: selectedIds,
        };
        deleteUnmatchedPayment.mutate(deletePaymentPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["unmatched"],
                });

                toast({
                    mode: "success",
                    message: `${buildSuccessMessageForDeletedUnMatchedPayments(
                        response.data.deleted_payments,
                        response.data.undeleted_payments
                    )}`,
                });
                setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Cannot delete payment at this time",
                });
            },
        });
    };

    const handleMatchPayment = (
        unmatched_payment_id: string,
        invoiceId: string
    ) => {
        const matchPaymentPayload = {
            unmatched_payment_matches: {
                [invoiceId]: [unmatched_payment_id],
            },
        };

        mutate(matchPaymentPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["unmatched"],
                });
                handleCloseBreakdownModal();
                toast({
                    mode: "success",
                    message: `${buildSuccessMessageForMatchedPayments(
                        response.data.added_payments,
                        response.data.unadded_payments
                    )}`,
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not match payment at this time, please try again later",
                });
            },
        });
    };

    // function to update payment status
    const handleRefreshPaymentStatus = () => {
        const selectedIds = getSelectedData
            ? getSelectedData.map((item) => item.original.unmatched_payment_id)
            : [];

        const updatePaymentStatusPayload = {
            payment_ids: selectedIds,
        };
        refreshPaymentStatus.mutate(updatePaymentStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["unmatched"],
                });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessageForRefreshPaymentStatus(
                        response.data.updated_payments,
                        response.data.unupdated_payments
                    )}`,
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "No payment was refreshed",
                });
            },
        });
    };

    // Onsubmit function for split payment form
    const onSplitPaymentFormSubmit = (payload: ISplitPaymentPayload) => {
        const dataToSend = {
            payment_id: currentUnmatchedPaymentId || "",
            invoices: payload.invoices.map((invoice, i) => ({
                amount: Number(invoice.amount),
                invoice_id:
                    getSelectedDataBreakdown?.[i].original.invoice_id || "",
            })),
        };

        splitPayment.mutate(dataToSend, {
            onSuccess: async (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Payment spliting successful",
                });
                await queryClient.invalidateQueries({
                    queryKey: ["unmatched"],
                });
                handleCloseBreakdownModal();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not split payment at this time, please try again later",
                });
            },
        });
    };

    // Table columns for main table (unmatched payment table)
    const columns: ColumnDef<IUnmatchedPayment>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <div>
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        id={row.original.transaction_id}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                </div>
            ),
        },

        {
            accessorKey: "client_name",
            header: "CLIENT",
            cell: ({ row }) => row.original.client_name || "--",
        },
        {
            accessorKey: "payment_date",
            header: "PAYMENT DATE",
            cell: ({ row }) => formatDate(row.original.payment_date),
        },
        {
            accessorKey: "payment_date",
            header: "PAYMENT time",
            cell: ({ row }) =>
                formatDate(
                    row.original.payment_date as string,
                    false,
                    "h:mmaaa"
                ).toUpperCase(),
        },
        {
            accessorKey: "payment_method",
            header: "PAYMENT METHOD",
            cell: ({ row }) => {
                return (
                    <span>
                        {makeStringFirstLetterCapital(
                            row.original.payment_method || "--"
                        )}
                        {(row.original.auth_code ||
                            row.original.cheque_number) && (
                            <span>
                                {" "}
                                -{" "}
                                {row.original.auth_code ||
                                    row.original.cheque_number ||
                                    "--"}
                            </span>
                        )}
                    </span>
                );
            },
        },
        {
            accessorKey: "payment_status",
            header: "PAYMENT STATUS",
            cell: ({ row }) =>
                getPaymentStatusTag(
                    row.original.payment_status as PaymentStatus
                ),
        },
        {
            accessorKey: "posted_by",
            header: "POSTED BY",
            cell: ({ row }) =>
                row.original.posted_by
                    ? `${row.original.posted_by.first_name} ${row.original.posted_by.last_name}` ||
                      "--"
                    : "--",
        },
        {
            accessorKey: "processor_payment_status",
            header: "PROCESSOR STATUS",
            cell: ({ row }) =>
                makeStringFirstLetterCapital(
                    row.original.processor_payment_status || "--"
                ),
        },
        {
            accessorKey: "transaction_id",
            header: "Transaction id",
            cell: ({ row }) => (
                <span className="flex items-center gap-x-4">
                    <span>{row.original.transaction_id || "--"}</span>
                    {row.original.transaction_id && (
                        <Button
                            size="auto"
                            variant="normal"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopyTransactionId(
                                    row.original.transaction_id
                                );
                            }}
                        >
                            <CopyIcon />
                        </Button>
                    )}
                </span>
            ),
        },
        {
            accessorKey: "payment_amount",
            header: "PAYMENT AMOUNT",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.amount) || "--",
        },
        {
            accessorKey: "open_bill",
            header: "OPEN BILL",
            cell: ({ row }) => row.original.open_invoice || "--",
        },
    ];

    // Columns for each payment breakdown table
    const breakdownColumns: ColumnDef<IBillingResponse>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <div
                    hidden={
                        row.original.coinsurance_amount_collected +
                            row.original.insurance_amount_collected >=
                        row.original.insurance_amount +
                            row.original.coinsurance_amount
                    }
                >
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        id={row.original.invoice_id}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                </div>
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service) || "--",
        },
        {
            accessorKey: "provider_name",
            header: "Provider",
            cell: ({ row }) =>
                row.original.provider.first_name
                    ? `${row.original.provider.first_name} ${row.original.provider.last_name}`
                    : "--",
        },
        {
            accessorKey: "cpt_code",
            header: "Cpt Code",
            cell: ({ row }) => row.original.cpt_code || "--",
        },
        {
            accessorKey: "insurance_amount",
            header: "Amount charged",
            cell: ({ row }) =>
                showMoneyInAppFormat(
                    row.original.insurance_amount +
                        row.original.coinsurance_amount
                ) || "--",
        },
        {
            accessorKey: "coinsurance_amount_collected",
            header: "Amount collected",
            cell: ({ row }) =>
                showMoneyInAppFormat(
                    row.original.coinsurance_amount_collected +
                        row.original.insurance_amount_collected
                ) || "--",
        },
        {
            accessorKey: "invoice_status",
            header: "Bill Status",
            cell: ({ row }) =>
                getBillStatusTag(row.original.invoice_status as BillStatus),
        },
    ];

    return (
        <>
            <Dialog open={dialogInview === "split_payment"}>
                <DialogContent
                    title="Split payment"
                    handleCloseDialog={() => {
                        setDialogInView("breakdown");
                        reset();
                    }}
                    variant="center"
                    showFooter
                    saveText="Split payment"
                    cancelText="Cancel"
                    submitBtnFormValue="split-payment"
                    isSubmitBtnDisabled={
                        splitPayment.isLoading ||
                        (invoices &&
                            invoices.reduce(
                                (accumulator, currentValue) =>
                                    accumulator + Number(currentValue.amount),
                                0
                            ) !== paymentInView?.amount)
                    }
                    isCancelBtnDisabled={
                        splitPayment.isLoading ||
                        (invoices &&
                            invoices.reduce(
                                (accumulator, currentValue) =>
                                    accumulator + currentValue.amount,
                                0
                            ) !== paymentInView?.amount)
                    }
                    classNames="px-0"
                >
                    <>
                        <div className="px-24">
                            <Alert
                                type="info"
                                title="Split payment on selected bills"
                                description={`Total amount of bills selected to split payment across must equal ${
                                    paymentInView
                                        ? showMoneyInAppFormat(
                                              paymentInView.amount
                                          )
                                        : "Payment amount"
                                }`}
                            />
                        </div>
                        <div className="mt-[22px]">
                            <p className="text-xs font-medium px-24 mb-12">
                                Input amount to split across bills
                            </p>
                            <form
                                onSubmit={handleSubmit(
                                    onSplitPaymentFormSubmit
                                )}
                                id="split-payment"
                            >
                                {getSelectedDataBreakdown?.map(
                                    (
                                        invoice: Row<IBillingResponse>,
                                        i: number
                                    ) => {
                                        const balance =
                                            invoice.original.insurance_amount +
                                                invoice.original
                                                    .coinsurance_amount -
                                                (invoice.original
                                                    .coinsurance_amount_collected +
                                                    invoice.original
                                                        .insurance_amount_collected) <=
                                            0
                                                ? 0
                                                : invoice.original
                                                      .insurance_amount +
                                                  invoice.original
                                                      .coinsurance_amount -
                                                  (invoice.original
                                                      .coinsurance_amount_collected +
                                                      invoice.original
                                                          .insurance_amount_collected);
                                        return (
                                            <div
                                                className="px-24 py-16 border-t border-t-strokelight"
                                                key={
                                                    invoice.original.invoice_id
                                                }
                                            >
                                                <div className="flex justify-between text-xs font-semibold items-center mb-12">
                                                    <p className="text-black">
                                                        {`Open Bill: ${formatDate(
                                                            invoice.original
                                                                .date_of_service
                                                        )}`}
                                                    </p>
                                                    <p className="text-[#FD1414]">
                                                        {`Balance: 
                                                    ${showMoneyInAppFormat(
                                                        balance
                                                    )}`}
                                                    </p>
                                                </div>
                                                <Input
                                                    {...register(
                                                        `invoices.${i}.amount`
                                                    )}
                                                    label="Split amount"
                                                    hasError={
                                                        !!errors?.invoices?.[i]
                                                            ?.amount
                                                    }
                                                    errorText={
                                                        errors?.invoices?.[i]
                                                            ?.amount?.message
                                                    }
                                                    showCurrency
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </form>
                        </div>
                    </>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInview === "breakdown"}>
                <DialogContent
                    title={`Open bills for ${
                        makeStringFirstLetterCapital(
                            paymentInView?.client_name || ""
                        ) || ""
                    }`}
                    showFooter={false}
                    handleCloseDialog={handleCloseBreakdownModal}
                    wrapperClass="w-[850px]"
                >
                    <>
                        {paymentInView && (
                            <div className="mb-20 grid grid-cols-4 gap-x-20 bg-white rounded-r8 border border-strokedark p-16">
                                <div className="flex flex-col gap-y-4 text-sm">
                                    <span className="text-gray font-regular">
                                        Payment Date
                                    </span>
                                    <span className="text-black font-medium">
                                        {`${formatDate(
                                            paymentInView.payment_date,
                                            false,
                                            "MM/dd/yyyy - h:mmaaa"
                                        )}` || "--"}
                                    </span>
                                </div>
                                <div className="flex flex-col gap-y-4 text-sm">
                                    <span className="text-gray font-regular">
                                        Payment method
                                    </span>
                                    <span className="text-black font-medium">
                                        {`${makeStringFirstLetterCapital(
                                            paymentInView.payment_method || "--"
                                        )} -  ${
                                            paymentInView.auth_code ||
                                            paymentInView.cheque_number ||
                                            "--"
                                        }`}
                                    </span>
                                </div>
                                <div className="flex flex-col gap-y-4 text-sm">
                                    <span className="text-gray font-regular">
                                        Payment amount
                                    </span>
                                    <span className="text-black font-medium">
                                        {showMoneyInAppFormat(
                                            paymentInView.amount
                                        ) || "--"}
                                    </span>
                                </div>
                                <div className="flex flex-col gap-y-4 text-sm">
                                    <span className="text-gray font-regular">
                                        Open bills
                                    </span>
                                    <span className="text-black font-medium">
                                        {paymentInView.open_invoice || "--"}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-end mb-20">
                            {canPerformActions &&
                                getSelectedDataBreakdown &&
                                getSelectedDataBreakdown.length === 1 && (
                                    <Button
                                        disabled={matchPaymentLoading}
                                        onClick={() =>
                                            handleMatchPayment(
                                                currentUnmatchedPaymentId as string,
                                                getSelectedDataBreakdown[0]
                                                    .original.invoice_id || ""
                                            )
                                        }
                                    >
                                        Match payment
                                    </Button>
                                )}
                            {canPerformActions &&
                                getSelectedDataBreakdown &&
                                getSelectedDataBreakdown.length > 1 && (
                                    <Button
                                        onClick={() =>
                                            setDialogInView("split_payment")
                                        }
                                    >
                                        Split payment
                                    </Button>
                                )}
                        </div>
                        {invoicesList.isLoading && (
                            <Skeleton
                                type="table"
                                containerTestId="invoices-list"
                                count={8}
                            />
                        )}
                        {invoicesList.error && (
                            <ListState
                                isError
                                stateHelperText="Try reloading this page to solve this issue"
                                errorMsg={
                                    error?.response?.data.message ||
                                    `Cannot display client invoices at this time please try again later`
                                }
                            />
                        )}
                        {invoicesList.data &&
                            invoicesList.isSuccess &&
                            invoicesList.data.data.length === 0 && (
                                <ListState
                                    stateHelperText="Client invoices list will appear here"
                                    isError={false}
                                    emptyMessage="There are no client invoice yet"
                                />
                            )}
                        {invoicesList.data &&
                            invoicesList.isSuccess &&
                            invoicesList.data.data.length > 0 && (
                                <Table
                                    columns={breakdownColumns}
                                    data={invoicesList.data.data || []}
                                    rowSelection={breakdownRowSelection}
                                    setRowSelection={setBreakdownRowSelection}
                                    setGetSelectedData={
                                        setGetSelectedDataBreakdown
                                    }
                                    enableRowSelection={
                                        handleBreakdownEnableRowSelection
                                    }
                                />
                            )}
                    </>
                </DialogContent>
            </Dialog>
            <div className="mb-32 bg-white rounded-r8 py-16 px-12">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        containerClass="w-[400px] max-w-full"
                        placeholder="Search by client name or chart number"
                        onChange={handleSetClientFilter}
                        defaultValue={clientFilter}
                    />
                    <div className="flex gap-x-12 items-center">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                >
                                    {customDateFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  customDateFilter
                                              )
                                          )
                                        : "Date range"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width={200}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {customDates.map((range) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    customDateFilter === range,
                                            }
                                        )}
                                        key={range}
                                        onClick={() =>
                                            handleCustomDateChange(range)
                                        }
                                    >
                                        {removeEnumUnderscore(range)}{" "}
                                        {customDateFilter === range ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                >
                                    Date
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                align="end"
                                className="h-auto w-96 px-16 pt-6 z-20"
                            >
                                <DatePicker
                                    label="From"
                                    placeholderText="MM/DD/YYYY"
                                    selected={fromDateFilter}
                                    onChange={handleFromDateFilterChange}
                                    className="hover:border-primary"
                                    maxDate={
                                        toDateFilter || new Date(Date.now())
                                    }
                                    isClearable
                                />
                                <div className="mt-24 mb-24 hover:border-primary">
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilter}
                                        onChange={handleToDateFilterChange}
                                        className="hover:border-primary"
                                        minDate={fromDateFilter}
                                        maxDate={new Date(Date.now())}
                                        isClearable
                                    />
                                </div>
                            </PopoverContent>
                        </Popover>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                >
                                    {invoiceStatusFilter
                                        ? truncateString(
                                              getInvoiceFilterTextToDisplay()
                                          )
                                        : "Show all bills"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width="auto"
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                invoiceStatusFilter === "",
                                        }
                                    )}
                                    onClick={() =>
                                        handleInvoiceStatusFilterChange("")
                                    }
                                >
                                    Show all bills
                                    {invoiceStatusFilter === "" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                invoiceStatusFilter ===
                                                "has_open_invoice",
                                        }
                                    )}
                                    onClick={() =>
                                        handleInvoiceStatusFilterChange(
                                            "has_open_invoice"
                                        )
                                    }
                                >
                                    Show payments with open bills
                                    {invoiceStatusFilter ===
                                    "has_open_invoice" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                invoiceStatusFilter ===
                                                "has_no_invoice",
                                        }
                                    )}
                                    onClick={() =>
                                        handleInvoiceStatusFilterChange(
                                            "has_no_invoice"
                                        )
                                    }
                                >
                                    Show payments with no bills
                                    {invoiceStatusFilter ===
                                    "has_no_invoice" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            </DropdownContent>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div>
                {canPerformActions && (
                    <div>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className="flex items-center mb-16 gap-x-12">
                                <div className="font-semibold text-base">
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }{" "}
                                    :
                                </div>{" "}
                                <Button
                                    className="bg-danger text-white hover:bg-danger/80"
                                    onClick={handleDeletePayment}
                                    disabled={deleteUnmatchedPayment.isLoading}
                                >
                                    Delete Unmatched Payments
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleRefreshPaymentStatus}
                                    disabled={refreshPaymentStatus.isLoading}
                                >
                                    Refresh payment status
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {fetchPaymentLoading && (
                <Skeleton
                    type="table"
                    containerTestId="unmatchedpayment-loader"
                    count={6}
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display unmatched payments at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    stateHelperText="UnMatched payment list will appear here"
                    isError={false}
                    emptyMessage="There are no unmatched payments yet"
                />
            )}
            {isSuccess && data && data.data.length > 0 && (
                <div>
                    <Table
                        columns={columns}
                        data={data.data}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        handleRowClick={handleRowClick}
                        setGetSelectedData={setGetSelectedData}
                        hasPagination={data.total_count > 20}
                        setSortConfig={setSortConfig}
                        sortConfig={sortConfig}
                        sortableColumns={sortableColumns}
                        pagination={
                            <Pagination
                                totalCount={data.total_count}
                                totalPages={data.total_pages}
                                currentPage={pageFilter}
                                onPageChange={handlePageFilter}
                                limit={limitFilter}
                                onLimitChange={handleLimitFilter}
                            />
                        }
                    />
                </div>
            )}
        </>
    );
}
