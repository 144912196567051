import { Tag } from "@jhool-io/fe-components";
import {
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../utils/helpers";
import { NoteStatus } from "../types/notes.types";

export const getNoteStatusTag = (status: NoteStatus, text?: string) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case NoteStatus.CODED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case NoteStatus.DRAFT:
            bgColor = "rgba(240, 240, 229, 0.50)";
            textColor = "#0B132B";
            break;
        case NoteStatus.CHANGES_REQUESTED:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case NoteStatus.PENDING_CODE_REVIEW:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case NoteStatus.PENDING_SUPERVISOR_REVIEW:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        default:
            bgColor = "rgba(240, 240, 229, 0.50)";
            textColor = "#0B132B";
    }

    return (
        <Tag
            title={`${text ? `${text} ` : ``}${makeStringFirstLetterCapital(
                removeEnumUnderscore(status)
            )}`}
            textColor={textColor}
            bgColor={bgColor}
            className="font-normal px-8 py-4 h-24 rounded-r4 text-nowrap"
        />
    );
};

export const getNoteListNavigateUrl = (userRoles: string[]) => {
    // Get note list filters from localstorage
    // This is a string of url searchParams `status%5B%5D=pending_supervisor_review&status%5B%5D=coded&status%5B%5D=changes_requested`
    const noteListFiltersFromLS = localStorage.getItem("notelist_filters");

    if (noteListFiltersFromLS) {
        return `/notes?${noteListFiltersFromLS}`;
    }

    if (userRoles.includes("provider")) {
        return `/notes?status[]=${NoteStatus.PENDING_SUPERVISOR_REVIEW}&status[]=${NoteStatus.DRAFT}&status[]=${NoteStatus.CHANGES_REQUESTED}&status[]=${NoteStatus.PENDING_CODE_REVIEW}`;
    }
    if (userRoles.includes("coder") || userRoles.includes("biller")) {
        return `/notes?status[]=${NoteStatus.PENDING_CODE_REVIEW}`;
    }
    return `/notes?status[]=${NoteStatus.PENDING_SUPERVISOR_REVIEW}&status[]=${NoteStatus.CODED}&status[]=${NoteStatus.CHANGES_REQUESTED}&status[]=${NoteStatus.PENDING_CODE_REVIEW}`;
};
