/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
} from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    IClaim,
    ClaimStatus,
    ClaimsHasRemit,
} from "../../../types/claims.types";
import { formatDate, showMoneyInAppFormat } from "../../../../../utils/helpers";
import { getStatusTag } from "../../../helpers/claims.helper";
import ListState from "../../../../../components/ListState/ListState";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import { useFetchClaims } from "../../../hooks/claims.queries";
import { IAppCustomDates } from "../../../../../utils/types";
import MoreButton from "../../../../../shared-ui/Buttons/MoreButton/MoreButton";
import { useUpdateClaimStatus } from "../../../hooks/claims.mutations";
import useToast from "../../../../../hooks/useToast";
import { LISTS_DEFAULT_LIMIT } from "../../../../../utils/constants";

interface ClosedClaimsListProps {
    searchString: string;
}

export default function ClosedClaimsList({
    searchString,
}: ClosedClaimsListProps) {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const pageFilter = Number(searchParams.get("closed_claims_page") || 1);
    const limitFilter =
        Number(searchParams.get("closed_claims_limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("note_dos_from");
    const toDateFilter = searchParams.get("note_dos_to");
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;
    const claimsStatusFilter = searchParams.get("status") as ClaimStatus;
    const hasRemitStatusFilter = searchParams.get("has_remits") || "";
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const sortableColumns = [
        "generated_on",
        "date_of_service",
        "submitted_on",
        "insurance_name",
        "claim_status",
        "client_name",
        "cpt_code",
    ];

    const patchClaimStatus = useUpdateClaimStatus();

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const getStatusFilter = () => {
        if (claimsStatusFilter) {
            if ([ClaimStatus.CLOSED].includes(claimsStatusFilter)) {
                return [claimsStatusFilter];
            }

            return [""];
        }

        return [ClaimStatus.CLOSED];
    };
    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }
        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const { data, error, isLoading, isSuccess } = useFetchClaims({
        claim_statuses: getStatusFilter(),
        custom_date: customDateFilter,
        search_string: searchString,
        note_dos_from: fromDateFilter || undefined,
        note_dos_to: toDateFilter || undefined,
        insurance_provider_id: insuranceProviderFilter,
        page: pageFilter,
        limit: limitFilter,
        has_remits: getHasRemitFilter(),
        sort_attribute:
            searchParams.get("closed_claims_sort_attr") ||
            handleConfigAttribute(),
        sort_order:
            searchParams.get("closed_claims_sort_order") ||
            sortConfig.direction,
    });

    const handleChangeStatus = (id: string, status: ClaimStatus) => {
        const changeStatusPayload = {
            claim_id: id,
            claim_status: status,
        };
        patchClaimStatus.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        "Claim status updated successfully",
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update claim status at this time",
                });
            },
        });
    };

    const columns: ColumnDef<IClaim>[] = [
        {
            accessorKey: "client_name",
            header: "CLIENT NAME",
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "claim_status",
            header: "STATUS",
            cell: ({ row }) => getStatusTag(row.original.claim_status),
        },
        {
            accessorKey: "insurance_name",
            header: "INSURANCE",
            cell: ({ row }) => (
                <span className="uppercase">{row.original.insurance_name}</span>
            ),
        },
        {
            accessorKey: "provider_name",
            header: "PROVIDER",
            cell: ({ row }) => <span>{row.original.provider_name}</span>,
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
        },

        {
            accessorKey: "charge_amount",
            header: "CHARGE",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charge_amount),
        },
        {
            header: "OUTSTANDING",
            columns: [
                {
                    accessorKey: "insurance_amount_outstanding",
                    header: "INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_outstanding
                        ),
                },
                {
                    accessorKey: "coinsurance_amount_outstanding",
                    header: "CO-INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_outstanding
                        ),
                },
            ],
        },
        {
            accessorKey: "external_reference_id",
            header: "CLAIM NO",
        },
        {
            accessorKey: "has_remits",
            header: "HAS REMITS",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.has_remits ? "text-primary" : "text-danger"
                    }
                >
                    {row.original.has_remits ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "accepts_assignment",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.accepts_assignment
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.accepts_assignment ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "claim_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <MoreButton />
                    </DropdownTrigger>
                    <DropdownContent width="auto" align="end">
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeStatus(
                                    row.original.claim_id,
                                    ClaimStatus.PENDING
                                );
                            }}
                        >
                            Mark as Pending
                        </DropdownItem>
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    const handleRowClick = (row: Row<IClaim>) => {
        navigate(
            `/claims/${row.original.claim_id}/${row.original.client_id}/${row.original.note_id}?key=claims&claims_view=view&status=${row.original.claim_status}&viewFrom=claims&tab=closed&claims_sub_tab=claims`
        );
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("closed_claims_page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("closed_claims_limit", String(limit));
        setSearchParams(searchParams);
    };

    return (
        <>
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg="Cannot display claims at this time please try again later"
                />
            )}
            {isLoading && (
                <Skeleton
                    containerTestId="closed-claims-loader"
                    type="table"
                    count={6}
                />
            )}
            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            stateHelperText="Closed claims will appear here once available "
                            emptyMessage="No claims yet"
                            isError={false}
                        />
                    )}

                    {data.data.length > 0 && (
                        <Table
                            columns={columns}
                            data={data.data}
                            handleRowClick={handleRowClick}
                            hasPagination={data.total_count > 20}
                            isHeaderFixed
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                            sortableColumns={sortableColumns}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    limit={limitFilter}
                                    onLimitChange={handleLimitChange}
                                />
                            }
                        />
                    )}
                </>
            )}
        </>
    );
}
