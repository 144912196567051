import {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
} from "@jhool-io/fe-components";
import React from "react";
import { useSearchParams } from "react-router-dom";
import FilterButton from "../../../../../shared-ui/Buttons/FilterButton/FilterButton";
import moment from "../../../../../utils/moment";
import { useFetchClientList } from "../../../../../hooks/queries/client";
import usePractice from "../../../../../hooks/usePractice";
import { UserRole } from "../../../../user-management/types/user-management.types";
import TableFiltersDisplay from "../../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import ScheduleWeekView from "../ScheduleWeekView/ScheduleWeekView";
import { isSmallScreen } from "../../../../../utils/helpers";
import NavigateLeft from "../../../../../components/Icons/NavigateLeft";
import NavigateRight from "../../../../../components/Icons/NavigateRight";

export default function ScheduleViewWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentWeek, setCurrentWeek] = React.useState(
        moment().startOf("week")
    );
    const { practice } = usePractice();
    const handleSetProviderId = () => {
        if (
            !practice?.roles?.includes(UserRole.AUDITOR) &&
            practice?.roles?.includes(UserRole.PROVIDER)
        ) {
            return practice?.provider_id;
        }

        return "";
    };
    const clientFilter = searchParams.getAll("client_ids[]") || [];

    const { data } = useFetchClientList({
        page: 1,
        provider_id: handleSetProviderId() as string,
    });

    // Function to handle week navigation
    const updateWeekRange = (direction: string) => {
        const newWeek =
            direction === "next"
                ? moment(currentWeek).add(1, "week")
                : moment(currentWeek).subtract(1, "week");
        searchParams.set(
            "from_date",
            newWeek.startOf("week").format("YYYY-MM-DD")
        );
        searchParams.set("to_date", newWeek.endOf("week").format("YYYY-MM-DD"));
        setSearchParams(searchParams);
        setCurrentWeek(newWeek);
    };

    const handleClientFilter = (client_id: string) => {
        if (clientFilter.includes(client_id)) {
            searchParams.delete("client_ids[]", client_id);
        } else {
            searchParams.append("client_ids[]", client_id);
        }
        setSearchParams(searchParams);
    };

    const startOfWeek = currentWeek.startOf("week").format("MMM D");
    const endOfWeek = currentWeek.endOf("week").format("D, YYYY");

    const currentWeekRange = `${startOfWeek} - ${endOfWeek}`;
    const filteredClientNames = data?.data
        .filter((client) => clientFilter.includes(client.client_id))
        .map((client) => `${client.first_name} ${client.last_name}`);

    return (
        <div>
            <div className="border border-[#E7E7E7] rounded-r8 h-fit bg-white flex items-center p-16">
                <div>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Client" />
                        </DropdownTrigger>
                        <DropdownContent
                            align={isSmallScreen() ? "start" : "end"}
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            {data?.data?.map((client) => (
                                <DropdownItem
                                    key={client.client_id}
                                    onClick={() =>
                                        handleClientFilter(client.client_id)
                                    }
                                    isMulti
                                    itemValue={client.client_id}
                                    values={clientFilter}
                                >
                                    {`${client.first_name} ${client.last_name}`}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "client_ids[]",
                                values: filteredClientNames as string[],
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>

                <div className="ml-auto mr-16">
                    <div className="flex items-center gap-x-16 text-sm font-medium">
                        <Button
                            variant="normal"
                            size="auto"
                            onClick={() => updateWeekRange("previous")}
                        >
                            <NavigateLeft />
                        </Button>
                        {currentWeekRange}
                        <Button
                            variant="normal"
                            size="auto"
                            onClick={() => updateWeekRange("next")}
                        >
                            <NavigateRight />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <ScheduleWeekView />
            </div>
        </div>
    );
}
