import { Tag } from "@jhool-io/fe-components";
import { ClaimStatus } from "../types/claims.types";
import { removeEnumUnderscore } from "../../../utils/helpers";

export const getStatusTag = (status: ClaimStatus, text?: string) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case ClaimStatus.ACKNOWLEDGED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case ClaimStatus.DENIED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case ClaimStatus.PAID:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case ClaimStatus.PENDING:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case ClaimStatus.RECEIVED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case ClaimStatus.PATIENT_PAYMENT_COLLECTION:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case ClaimStatus.CLOSED:
            textColor = "#0B132B";
            bgColor = "rgba(229, 229, 229, 0.50)";
            break;
        case ClaimStatus.SUBMITTED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case ClaimStatus.APPEALED:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case ClaimStatus.REJECTED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case ClaimStatus.READY_FOR_SUBMISSION:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case ClaimStatus.REVERSED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case ClaimStatus.CODED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        default:
            textColor = "#212121";
            bgColor = "rgba(245, 245, 245, 0.50)";
    }

    return (
        <Tag
            title={`${text ? `${text} ` : ``}${removeEnumUnderscore(status)}`}
            textColor={textColor}
            bgColor={bgColor}
            className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
        />
    );
};

export const calculateClaimAge = (time: string) => {
    const dateSubmitted = new Date(time);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - dateSubmitted.getTime();

    // Convert milliseconds to seconds
    const secondsDifference = Math.floor(timeDifference / 1000);

    if (secondsDifference < 60) {
        return `${secondsDifference} seconds`;
    }
    if (secondsDifference < 3600) {
        const minutes = Math.floor(secondsDifference / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
    if (secondsDifference < 86400) {
        const hours = Math.floor(secondsDifference / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""}`;
    }
    const days = Math.floor(secondsDifference / 86400);
    return `${days} day${days > 1 ? "s" : ""}`;
};

/**
 * Handles the extraction of the separate parts of a phone number, i.e country code, area code & number. Returns an array of all three if passed a valid number string.
 * @param phoneNumberString
 * @returns [countryCode, areaCode, phoneNumber]
 */
export const getPhoneNumberParts = (phoneNumberString: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return [match[1], match[2], `${match[3]}-${match[4]}`];
    }
    return [];
};

export const getClaimsListNavigateUrl = () => {
    const claimsListFiltersFromLS = localStorage.getItem("claimslist_filters");

    if (claimsListFiltersFromLS) {
        return `/claims?${claimsListFiltersFromLS}`;
    }
    return `/claims`;
};
