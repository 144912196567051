import {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    SearchInput,
} from "@jhool-io/fe-components";
import React from "react";
import { useSearchParams } from "react-router-dom";
import FilterButton from "../../../../../shared-ui/Buttons/FilterButton/FilterButton";
import moment from "../../../../../utils/moment";
import TableFiltersDisplay from "../../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useFetchClientList } from "../../../../../hooks/queries/client";
import { UserRole } from "../../../../user-management/types/user-management.types";
import usePractice from "../../../../../hooks/usePractice";
import { isSmallScreen } from "../../../../../utils/helpers";
import { useFetchProviders } from "../../../../providers/hooks/providers.queries";
import ScheduleWeekView from "../ScheduleWeekView/ScheduleWeekView";
import { useDebounce } from "../../../../../hooks/helpers";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import NavigateLeft from "../../../../../components/Icons/NavigateLeft";
import NavigateRight from "../../../../../components/Icons/NavigateRight";

export default function BillerScheduleWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentWeek, setCurrentWeek] = React.useState(
        moment().startOf("week")
    );
    const { practice } = usePractice();
    const handleSetProviderId = () => {
        if (
            !practice?.roles?.includes(UserRole.AUDITOR) &&
            practice?.roles?.includes(UserRole.PROVIDER)
        ) {
            return practice?.provider_id;
        }

        return "";
    };
    const [clientSearchString, setClientSearchString] = React.useState("");
    const [providerSearchString, setProviderSearchString] = React.useState("");
    const clientFilter = searchParams.getAll("client_ids[]") || [];
    const providerFilter = searchParams.getAll("provider_ids[]") || [];

    const debouncedProviderSearchValue = useDebounce(providerSearchString, 500);
    const debouncedClientSearchValue = useDebounce(clientSearchString, 500);

    const { data, isLoading, error } = useFetchClientList({
        page: 1,
        search_string: debouncedClientSearchValue,
        provider_id: handleSetProviderId() as string,
    });

    const {
        data: providers,
        isLoading: providerListLoading,
        error: providerListError,
    } = useFetchProviders({
        page: 1,
        search_string: debouncedProviderSearchValue,
        limit: 20,
    });

    // Function to handle week navigation
    const updateWeekRange = (direction: string) => {
        const newWeek =
            direction === "next"
                ? moment(currentWeek).add(1, "week")
                : moment(currentWeek).subtract(1, "week");
        searchParams.set(
            "from_date",
            newWeek.startOf("week").format("YYYY-MM-DD")
        );
        searchParams.set("to_date", newWeek.endOf("week").format("YYYY-MM-DD"));
        setSearchParams(searchParams);
        setCurrentWeek(newWeek);
    };

    const handleClientFilter = (client_id: string) => {
        if (clientFilter.includes(client_id)) {
            searchParams.delete("client_ids[]", client_id);
        } else {
            searchParams.append("client_ids[]", client_id);
        }
        setSearchParams(searchParams);
    };

    const handleProviderFilter = (provider_id: string) => {
        if (providerFilter.includes(provider_id)) {
            searchParams.delete("provider_ids[]", provider_id);
        } else {
            searchParams.append("provider_ids[]", provider_id);
        }
        setSearchParams(searchParams);
    };

    const startOfWeek = currentWeek.startOf("week").format("MMM D");
    const endOfWeek = currentWeek.endOf("week").format("D, YYYY");

    const currentWeekRange = `${startOfWeek} - ${endOfWeek}`;

    const filteredClientNames = data?.data
        .filter((client) => clientFilter.includes(client.client_id))
        .map((client) => `${client.first_name} ${client.last_name}`);

    const filteredProviderNames = providers?.data
        .filter((provider) => providerFilter.includes(provider.provider_id))
        .map((provider) => provider.provider_name);

    return (
        <div>
            <div className="border border-[#E7E7E7] rounded-r8 h-fit bg-white p-16">
                <div className="flex items-center">
                    <div className="flex gap-x-12 ">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton text="Clients" />
                            </DropdownTrigger>
                            <DropdownContent
                                align={isSmallScreen() ? "start" : "end"}
                                width="300px"
                                maxHeight={216}
                                className="overflow-y-auto"
                                collisionPadding={10}
                            >
                                <SearchInput
                                    placeholder="Search by client name"
                                    containerClass="h-24"
                                    onKeyDown={(e) => {
                                        if (e.key !== "Escape") {
                                            e.stopPropagation();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setClientSearchString(e.target.value);
                                    }}
                                />
                                {isLoading && (
                                    <div className="flex flex-col gap-y-4 items-stretch w-full">
                                        {[1, 2, 3, 4, 5].map((item) => (
                                            <Skeleton
                                                key={item}
                                                width="100%"
                                                height="24px"
                                                containerTestId="provider-loader"
                                            />
                                        ))}
                                    </div>
                                )}
                                {error && (
                                    <ListState
                                        isError
                                        context="general"
                                        stateHelperText="Please try again later"
                                        errorMsg={
                                            providerListError?.response?.data
                                                ?.message ||
                                            "Error loading clinicians"
                                        }
                                    />
                                )}
                                {data?.data?.map((client) => (
                                    <DropdownItem
                                        key={client.client_id}
                                        onClick={() =>
                                            handleClientFilter(client.client_id)
                                        }
                                        isMulti
                                        itemValue={client.client_id}
                                        values={clientFilter}
                                    >
                                        {`${client.first_name} ${client.last_name}`}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>

                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton text="Providers" />
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width="300px"
                                maxHeight={216}
                                className="overflow-y-auto"
                                collisionPadding={10}
                            >
                                <SearchInput
                                    placeholder="Search by provider name"
                                    containerClass="h-24"
                                    onKeyDown={(e) => {
                                        if (e.key !== "Escape") {
                                            e.stopPropagation();
                                        }
                                    }}
                                    onChange={(e) =>
                                        setProviderSearchString(e.target.value)
                                    }
                                />
                                {providerListLoading && (
                                    <div className="flex flex-col gap-y-4 items-stretch w-full">
                                        {[1, 2, 3, 4, 5].map((item) => (
                                            <Skeleton
                                                key={item}
                                                width="100%"
                                                height="24px"
                                                containerTestId="provider-loader"
                                            />
                                        ))}
                                    </div>
                                )}
                                {providerListError && (
                                    <ListState
                                        isError
                                        context="general"
                                        stateHelperText="Please try again later"
                                        errorMsg={
                                            providerListError?.response?.data
                                                ?.message ||
                                            "Error loading clinicians"
                                        }
                                    />
                                )}
                                {providers?.data?.map((provider) => (
                                    <DropdownItem
                                        key={provider.provider_id}
                                        onClick={() =>
                                            handleProviderFilter(
                                                provider.provider_id
                                            )
                                        }
                                        isMulti
                                        itemValue={provider.provider_id}
                                        values={providerFilter}
                                    >
                                        {provider.provider_name}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                    </div>
                    <div className="ml-auto mr-16">
                        <div className="flex items-center gap-x-16 text-sm font-medium">
                            <Button
                                variant="normal"
                                size="auto"
                                onClick={() => {
                                    updateWeekRange("previous");
                                }}
                            >
                                <NavigateLeft />
                            </Button>
                            {currentWeekRange}
                            <Button
                                variant="normal"
                                size="auto"
                                onClick={() => {
                                    updateWeekRange("next");
                                }}
                            >
                                <NavigateRight />
                            </Button>
                        </div>
                    </div>
                </div>
                <TableFiltersDisplay
                    appliedFilters={[
                        {
                            key: "client_ids[]",
                            values: filteredClientNames || [],
                        },
                        {
                            key: "provider_ids[]",
                            values: filteredProviderNames || [],
                        },
                    ]}
                    wrapperClass="mt-12"
                />
            </div>
            <div>
                <ScheduleWeekView />
            </div>
        </div>
    );
}
