import { SessionServiceType } from "../../../utils/types/session";
import { RecurrenceRule } from "../../schedule/types/schedule.types";

export interface ISessionSummary {
    no_of_sessions_scheduled: number;
    no_of_notes_completed: number;
    no_of_uncompleted_notes: number;
    no_of_late_notes: number;
    no_of_notes_flagged_for_audit: number;
}

interface Remit {
    amount: number;
    client_name: string;
    date_of_service: string;
}

interface DraftClaim {
    client_name: string;
    cpt_code: string;
    date_of_service: string;
}

export interface ExpiringPolicy {
    client_name: string;
    client_id: string;
    end_date: string;
    start_date: string;
    insurance_member_id: string;
    employer_name: string;
    non_client_primary_insured_name: string;
    insurance_provider: string;
    date_of_birth: string;
    financial_assistance_end_date?: string;
}

export interface IBillingOverViewResponse {
    remits: Remit[];
    total_remits: number;
    draft_claims: DraftClaim[];
    total_draft_claims: number;
    expiring_policies: ExpiringPolicy[];
    total_expiring_policies: number;
}

export interface ICalendarSchedule {
    provider_id: string;
    id: string;
    first_name: string;
    last_name: string;
    client_id: string;
    title: string | null;
    recurrence_rule: RecurrenceRule;
    day: string;
    start_hr: number;
    start_date: string;
    start_min: number;
    end_min: number;
    end_hr: number;
    entry_type: string;
    is_paused: boolean;
    pending_task: boolean;
    converted_date_time_utc: string;
    next_session_date: string;
    service: SessionServiceType;
    session_history_id?: string;
    insurance_provider: string;
}

export enum ExpiryDateRange {
    ALL_TIME = "all_time",
    LAST_24_HRS = "last_24_hrs",
    LAST_WEEK = "last_week",
    LAST_MONTH = "last_month",
    LAST_30_DAYS = "last_30_days",
    LAST_60_DAYS = "last_60_days",
    TODAY = "today",
    THIS_WEEK = "this_week",
    NEXT_WEEK = "next_week",
    NEXT_TWO_WEEKS = "next_two_weeks",
    THIS_MONTH = "this_month",
    NEXT_MONTH = "next_month",
    THIS_YEAR = "this_year",
}
