import { format, getYear } from "date-fns";
import { Tag } from "@jhool-io/fe-components";
import {
    BillPaymentStatus,
    BillStatus,
    BillStatusLabels,
} from "../types/billing.types";
import { removeEnumUnderscore } from "../../../utils/helpers";

const currentYear = getYear(new Date());

export const BillingSummaryCardsFilters = [
    "year_to_date",
    `${currentYear - 1}`,
    `${currentYear - 2}`,
    `${currentYear - 3}`,
] as const;

export const billingPaymentMonths = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

// Get correct from_date and to_date from BillingCardsFilters
export const getTimeRangeFromFilterValue = (filter: string) => {
    switch (filter) {
        case BillingSummaryCardsFilters[0]:
            return {
                fromDate: `${currentYear}-01-01`,
                toDate: format(new Date(), `yyyy-MM-dd`),
            };

        case BillingSummaryCardsFilters[1]:
            return {
                fromDate: `${BillingSummaryCardsFilters[1]}-01-01`,
                toDate: `${BillingSummaryCardsFilters[1]}-12-31`,
            };

        case BillingSummaryCardsFilters[2]:
            return {
                fromDate: `${BillingSummaryCardsFilters[2]}-01-01`,
                toDate: `${BillingSummaryCardsFilters[2]}-12-31`,
            };

        case BillingSummaryCardsFilters[3]:
            return {
                fromDate: `${BillingSummaryCardsFilters[3]}-01-01`,
                toDate: `${BillingSummaryCardsFilters[3]}-12-31`,
            };

        default:
            return {
                fromDate: "",
                toDate: "",
            };
    }
};

export const getBillStatusTag = (status: BillStatus, text?: string) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case BillStatus.OPEN:
            textColor = "#686B1A";
            bgColor = "#E7E7DA";
            break;
        case BillStatus.CLOSED:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
            break;
        case BillStatus.PENDING_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        case BillStatus.PENDING_PATIENT_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        case BillStatus.PENDING_INSURANCE_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        default:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
    }

    return (
        <Tag
            textColor={textColor}
            bgColor={bgColor}
            title={`${text ? `${text} ` : ``}${removeEnumUnderscore(
                BillStatusLabels[status]
            )}`}
            className="capitalize w-auto h-auto text-nowrap py-4 px-8 rounded-r4"
        />
    );
};

export const getBillPaymentStatusTag = (status: BillPaymentStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case BillPaymentStatus.COMPLETED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case BillPaymentStatus.FAILED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case BillPaymentStatus.PENDING:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        case BillPaymentStatus.REFUNDED:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case BillPaymentStatus.VOIDED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        default:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
    }

    return (
        <Tag
            textColor={textColor}
            bgColor={bgColor}
            title={removeEnumUnderscore(status)}
            className="capitalize w-auto h-auto text-nowrap py-4 px-8 rounded-r4"
        />
    );
};

export const getBillingSummaryNavigateUrl = () => {
    // Get billing summary filters from localstorage
    const billingSummaryFiltersFromLS = localStorage.getItem(
        "billingsummary_filters"
    );

    if (billingSummaryFiltersFromLS) {
        return `/billing-summary?${billingSummaryFiltersFromLS}`;
    }
    return `/billing-summary`;
};
