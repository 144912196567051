/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
} from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    IClaim,
    ClaimStatus,
    ClaimsHasRemit,
} from "../../../types/claims.types";
import {
    formatDate,
    getTotalAmountOfTableRowsSelected,
    showMoneyInAppFormat,
} from "../../../../../utils/helpers";
import MoreIcon from "../../../../../components/Icons/More";
import {
    calculateClaimAge,
    getStatusTag,
} from "../../../helpers/claims.helper";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import InfoCircleIcon from "../../../../../components/Icons/InfoCircle";
import { useFetchClaims } from "../../../hooks/claims.queries";
import ListState from "../../../../../components/ListState/ListState";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import { IAppCustomDates } from "../../../../../utils/types";
import useToast from "../../../../../hooks/useToast";
import {
    useBulkCreateClaim,
    useUpdateClaimStatus,
} from "../../../hooks/claims.mutations";
import BulkUpdateClaimForm from "../BulkUpdateClaimsForm/BulkUpdateClaimsForm";
import { LISTS_DEFAULT_LIMIT } from "../../../../../utils/constants";

interface RequiresActionListProps {
    searchString: string;
}

export default function RequiresActionList({
    searchString,
}: RequiresActionListProps) {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedClaimsData, setGetSelectedClaimsData] = React.useState<
        Row<IClaim>[]
    >([]);
    const [showDialog, setShowDialog] = React.useState(false);
    const [showBulkRegenerateDialog, setShowBulkRegenerateDialog] =
        React.useState(false);
    const [statusToSubmit, setStatusToSubmit] =
        React.useState<ClaimStatus | null>(null);

    const pageFilter = Number(searchParams.get("req_action_page") || 1);
    const limitFilter =
        Number(searchParams.get("req_action_limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("note_dos_from");
    const toDateFilter = searchParams.get("note_dos_to");
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;
    const claimsStatusFilter = searchParams.get("status") as ClaimStatus;
    const hasRemitStatusFilter = searchParams.get("has_remits") || "";

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const sortableColumns = [
        "generated_on",
        "date_of_service",
        "submitted_on",
        "insurance_name",
        "claim_status",
        "client_name",
        "cpt_code",
    ];

    const getStatusFilter = () => {
        if (claimsStatusFilter) {
            if (
                [ClaimStatus.REJECTED, ClaimStatus.DENIED].includes(
                    claimsStatusFilter
                )
            ) {
                return [claimsStatusFilter];
            }

            return [""];
        }

        return [ClaimStatus.REJECTED, ClaimStatus.DENIED];
    };

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }
        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const { data, error, isLoading, isSuccess } = useFetchClaims({
        claim_statuses: getStatusFilter(),
        search_string: searchString,
        note_dos_from: fromDateFilter || undefined,
        note_dos_to: toDateFilter || undefined,
        custom_date: customDateFilter,
        insurance_provider_id: insuranceProviderFilter,
        page: pageFilter,
        limit: limitFilter,
        has_remits: getHasRemitFilter(),
        sort_attribute:
            searchParams.get("req_action_sort_attr") || handleConfigAttribute(),
        sort_order:
            searchParams.get("req_action_sort_order") || sortConfig.direction,
    });

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["bulk-update-claims"]);

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const patchClaimStatus = useUpdateClaimStatus();
    const bulkRegenerateClaims = useBulkCreateClaim();

    // function to change claim status
    const handleChangeStatus = (id: string, status: ClaimStatus) => {
        const changeStatusPayload = {
            claim_id: id,
            claim_status: status,
        };
        patchClaimStatus.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        "Claim status updated successfully",
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update claim status at this time",
                });
            },
        });
    };

    // Array of selected claims ids
    const selectedClaimsIds =
        getSelectedClaimsData &&
        getSelectedClaimsData.map(
            (item: Row<IClaim>) => item.original.claim_id
        );

    const claimsDetails =
        selectedClaimsIds &&
        selectedClaimsIds.map((id: string) =>
            data?.data.find((claim) => claim.claim_id === id)
        );

    // const checkClaimStatus = useCheckClaimStatus();

    const handleRowClick = (row: Row<IClaim>) => {
        navigate(
            `/claims/${row.original.claim_id}/${row.original.client_id}/${row.original.note_id}?key=claims&claims_view=view&status=${row.original.claim_status}&viewFrom=claims&tab=requires_action&claims_sub_tab=claims`
        );
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("req_action_page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("req_action_limit", String(limit));
        setSearchParams(searchParams);
    };

    const showPaidClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) => claim && claim.claim_status === ClaimStatus.PAID
            ).length;

    const showDeniedClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) => claim && claim.claim_status === ClaimStatus.DENIED
            ).length;

    const showAppealedClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) => claim && claim.claim_status === ClaimStatus.APPEALED
            ).length;

    const showReversedClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) => claim && claim.claim_status === ClaimStatus.REVERSED
            ).length;

    const showPatientPaymentClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) =>
                    claim &&
                    claim.claim_status ===
                        ClaimStatus.PATIENT_PAYMENT_COLLECTION
            ).length;

    const showBulkUpdateClaimsAction =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) =>
                    (claim && claim.claim_status === ClaimStatus.REJECTED) ||
                    (claim && claim.claim_status === ClaimStatus.DENIED)
            ).length;

    const handleDisplayDialog = (status: ClaimStatus) => {
        setStatusToSubmit(status);
        setShowDialog(true);
    };

    // const handleCheckClaimStatus = (ids: string[]) => {
    //     if (checkClaimStatus.isLoading) {
    //         toast({
    //             mode: "loading",
    //             title: "Running status",
    //             message:
    //                 "This won’t take a minute. Sit back and relax as we retrieve the status",
    //         });
    //     }

    //     checkClaimStatus.mutate(ids, {
    //         onSuccess(res) {
    //             queryClient.invalidateQueries(["claims"]);
    //             toast({
    //                 mode: "success",
    //                 message: `${res.message}`,
    //             });
    //             setRowSelection({});
    //         },
    //         onError(err) {
    //             toast({
    //                 mode: "error",
    //                 message:
    //                     err.response?.data.message ||
    //                     "Could not check claims status, please try again",
    //             });
    //         },
    //     });
    // };

    const handleBulkRegenerateClaims = () => {
        const noteIds =
            data?.data
                .filter((claim) => selectedClaimsIds?.includes(claim.claim_id))
                .map((claim) => claim.note_id) || [];

        bulkRegenerateClaims.mutate(
            { note_ids: noteIds },
            {
                onSuccess: async ({ message, data: res }) => {
                    await queryClient.invalidateQueries(["claims"]);

                    toast({
                        mode: "success",
                        message: `${res.regenerated_claims.length} claim(s) updated, ${res.unregenerated_claims.length} claim(s) not updated`,
                        title: message || `Claims updated successfully`,
                    });

                    setShowBulkRegenerateDialog(false);
                    setRowSelection({});
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Cannot update claims at this time",
                    });
                },
            }
        );
    };

    const columns: ColumnDef<IClaim>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "submitted_on",
            header: "DATE SUBMITTED",
            cell: ({ row }) =>
                row.original.submitted_on
                    ? formatDate(row.original.submitted_on)
                    : "--",
        },
        {
            accessorKey: "client_name",
            header: "CLIENT NAME",
        },
        {
            accessorKey: "insurance_name",
            header: "INSURANCE",
            cell: ({ row }) => (
                <span className="uppercase">{row.original.insurance_name}</span>
            ),
        },
        {
            accessorKey: "provider_name",
            header: "PROVIDER",
            cell: ({ row }) => <span>{row.original.provider_name}</span>,
        },
        {
            accessorKey: "claim_status",
            header: "STATUS",
            cell: ({ row }) => (
                <div className="flex items-center gap-x-8">
                    <span>{getStatusTag(row.original.claim_status)}</span>
                    <Tooltip
                        content={
                            <div className="flex gap-x-[58px]">
                                <div className="flex flex-col">
                                    <span className="text-xs font-semibold">
                                        STATUS NOTES
                                    </span>
                                    <span className="font-medium text-[#FA556B]">
                                        {row.original.status_notes
                                            ? row.original.status_notes
                                            : "---"}
                                    </span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-xs font-semibold">
                                        AS OF
                                    </span>
                                    <span className="font-medium">
                                        {formatDate(
                                            row.original.status_updated_at
                                        )}
                                        <span className="text-gray ml-1">
                                            {formatDate(
                                                row.original.status_updated_at,
                                                false,
                                                "h:mm a"
                                            )}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        }
                        showArrow
                    >
                        <span>
                            <InfoCircleIcon stroke="#858CA0" fill="#858CA0" />
                        </span>
                    </Tooltip>
                </div>
            ),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
        },
        {
            accessorKey: "external_reference_id",
            header: "CLAIM NO",
        },
        {
            accessorKey: "charge_amount",
            header: "CHARGE",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charge_amount),
        },
        {
            header: "OUTSTANDING",
            columns: [
                {
                    accessorKey: "insurance_amount_outstanding",
                    header: "INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_outstanding
                        ),
                },
                {
                    accessorKey: "coinsurance_amount_outstanding",
                    header: "CO-INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_outstanding
                        ),
                },
            ],
        },
        {
            accessorKey: "has_remits",
            header: "HAS REMITS",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.has_remits ? "text-primary" : "text-danger"
                    }
                >
                    {row.original.has_remits ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "submitted_on",
            header: "AGE",
            cell: ({ row }) =>
                `${
                    row.original.submitted_on
                        ? calculateClaimAge(row.original.submitted_on)
                        : "--"
                }`,
        },
        {
            accessorKey: "accepts_assignment",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.accepts_assignment
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.accepts_assignment ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "claim_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <Button
                            size="auto"
                            variant="normal"
                            className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800 flex align-top mt-1"
                        >
                            <MoreIcon />
                        </Button>
                    </DropdownTrigger>

                    <DropdownContent width="auto" align="end">
                        {(row.original.claim_status as ClaimStatus) ===
                            ClaimStatus.REJECTED && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        row.original.claim_id,
                                        ClaimStatus.CLOSED
                                    );
                                }}
                            >
                                Mark as Closed
                            </DropdownItem>
                        )}
                        {row.original.claim_status === ClaimStatus.DENIED && (
                            <>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.APPEALED
                                        );
                                    }}
                                >
                                    Mark as Appealed
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.CLOSED
                                        );
                                    }}
                                >
                                    Mark as Closed
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                        );
                                    }}
                                >
                                    Patient payment collection
                                </DropdownItem>
                            </>
                        )}
                        {row.original.claim_status === ClaimStatus.PAID && (
                            <>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.PENDING
                                        );
                                    }}
                                >
                                    Mark as Pending
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                        );
                                    }}
                                >
                                    Patient payment collection
                                </DropdownItem>
                            </>
                        )}
                        {row.original.claim_status === ClaimStatus.APPEALED && (
                            <>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.PENDING
                                        );
                                    }}
                                >
                                    Mark as Pending
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.DENIED
                                        );
                                    }}
                                >
                                    Mark as Denied
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeStatus(
                                            row.original.claim_id,
                                            ClaimStatus.PAID
                                        );
                                    }}
                                >
                                    Mark as Paid
                                </DropdownItem>
                            </>
                        )}
                        {row.original.claim_status === ClaimStatus.REVERSED && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        row.original.claim_id,
                                        ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                    );
                                }}
                            >
                                Patient payment collection
                            </DropdownItem>
                        )}
                        {(row.original.claim_status === ClaimStatus.REVERSED ||
                            row.original.claim_status ===
                                ClaimStatus.PATIENT_PAYMENT_COLLECTION ||
                            row.original.claim_status === ClaimStatus.PAID) && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        row.original.claim_id,
                                        ClaimStatus.CLOSED
                                    );
                                }}
                            >
                                Mark as Closed
                            </DropdownItem>
                        )}
                        {/* <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCheckClaimStatus([row.original.claim_id]);
                            }}
                        >
                            Check Status
                        </DropdownItem> */}
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            {statusToSubmit && selectedClaimsIds && (
                <Dialog open={showDialog}>
                    <DialogContent
                        title="Update Claims status"
                        showFooter
                        saveText="Save changes"
                        cancelText="Cancel"
                        variant="center"
                        handleCloseDialog={() => setShowDialog(false)}
                        submitBtnFormValue="bulk-update-claims"
                        isSubmitBtnDisabled={isMutating > 0}
                        isCancelBtnDisabled={isMutating > 0}
                    >
                        <BulkUpdateClaimForm
                            claimStatus={statusToSubmit}
                            claimsId={selectedClaimsIds}
                            onFormSubmit={() => {
                                setRowSelection({});
                                setShowDialog(false);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={showBulkRegenerateDialog}>
                <DialogContent
                    variant="center"
                    title="Update claims"
                    showFooter
                    cancelText="Cancel"
                    saveText="Yes, Update Claims"
                    onSaveClick={handleBulkRegenerateClaims}
                    handleCloseDialog={() => setShowBulkRegenerateDialog(false)}
                    isSubmitBtnDisabled={bulkRegenerateClaims.isLoading}
                    isCancelBtnDisabled={bulkRegenerateClaims.isLoading}
                    classNames="pt-24 pb-48"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to update claims
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will update claims with recent changes to the
                            client, provider and practice? This action will
                            update the data on the claims
                        </p>
                    </div>
                </DialogContent>
            </Dialog>

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg="Cannot display claims at this time please try again later"
                />
            )}
            {isLoading && (
                <Skeleton
                    containerTestId="requires-action-claims-loader"
                    type="table"
                    count={6}
                />
            )}
            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            stateHelperText="Claims requiring action will appear here once available "
                            emptyMessage="No claims yet"
                            isError={false}
                        />
                    )}

                    {data.data.length > 0 && (
                        <>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount > 0 && (
                                <div className="flex items-center mb-6">
                                    <div className="font-medium text-base">
                                        {
                                            getTotalAmountOfTableRowsSelected(
                                                rowSelection
                                            ).amountString
                                        }
                                        :
                                    </div>
                                    {showBulkUpdateClaimsAction && (
                                        <Button
                                            variant="secondary"
                                            className=" ml-12"
                                            onClick={() =>
                                                setShowBulkRegenerateDialog(
                                                    true
                                                )
                                            }
                                        >
                                            Update
                                        </Button>
                                    )}
                                    {showDeniedClaimsActions && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.APPEALED
                                                    )
                                                }
                                            >
                                                Appeal
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                    )
                                                }
                                            >
                                                Patient payment collection
                                            </Button>
                                        </>
                                    )}

                                    {showPaidClaimsActions && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PENDING
                                                    )
                                                }
                                            >
                                                Mark as Pending
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                    )
                                                }
                                            >
                                                Patient payment collection
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.CLOSED
                                                    )
                                                }
                                            >
                                                Mark as Closed
                                            </Button>
                                        </>
                                    )}

                                    {showPatientPaymentClaimsActions && (
                                        <Button
                                            variant="secondary"
                                            className=" ml-12"
                                            onClick={() =>
                                                handleDisplayDialog(
                                                    ClaimStatus.CLOSED
                                                )
                                            }
                                        >
                                            Mark as Closed
                                        </Button>
                                    )}

                                    {showAppealedClaimsActions && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PENDING
                                                    )
                                                }
                                            >
                                                Mark as Pending
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.DENIED
                                                    )
                                                }
                                            >
                                                Mark as Denied
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PAID
                                                    )
                                                }
                                            >
                                                Mark as Paid
                                            </Button>
                                        </>
                                    )}

                                    {showReversedClaimsActions && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                    )
                                                }
                                            >
                                                Patient payment collection
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className=" ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.CLOSED
                                                    )
                                                }
                                            >
                                                Mark as Closed
                                            </Button>
                                        </>
                                    )}

                                    {/* <Button
                                        variant="secondary"
                                        className=" ml-12"
                                        onClick={() =>
                                            handleCheckClaimStatus(
                                                selectedClaimsIds
                                            )
                                        }
                                    >
                                        Check status
                                    </Button> */}
                                </div>
                            )}
                            <Table
                                columns={columns}
                                data={data.data}
                                handleRowClick={handleRowClick}
                                setRowSelection={setRowSelection}
                                rowSelection={rowSelection}
                                setGetSelectedData={setGetSelectedClaimsData}
                                hasPagination={data.total_count > 20}
                                isHeaderFixed
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
