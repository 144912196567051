import {
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tag,
} from "@jhool-io/fe-components";

import { useSearchParams } from "react-router-dom";
import styles from "./ScheduleWeekView.module.scss";
import usePractice from "../../../../../hooks/usePractice";
import { IWeeklySchedule } from "../../../types/schedule.types";
import { useFetchWeeklySchedule } from "../../../hooks/schedule.queries";
import moment from "../../../../../utils/moment";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import { getColorStylesForCalendarId } from "../../../../../utils/constants";
import useShowInsuranceOverSelfPay from "../../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import {
    convertTimeTo12HoursFormat,
    formatDate,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../../utils/helpers";
import { NoteStatus } from "../../../../notes/types/notes.types";
import BillerScheduleDetails from "../ScheduleDetails/ScheduleDetails";
import {
    getBillStatusTag,
    getNoteStatusTag,
} from "../../../helpers/schedule.helpers";
import { BillStatus } from "../../../../billing/types/billing.types";

// single event in the calendar
function SingleSchedule({ schedule }: { schedule: IWeeklySchedule }) {
    const { recurrence_rule: recurrenceRule } = schedule;
    const colorStyles = getColorStylesForCalendarId(recurrenceRule as string);
    const style = {
        background: colorStyles.backgroundColor,
        borderLeft: colorStyles.color,
        height: "100%",
    };

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const formattedDate = handleFormatDatePickerValue(
        schedule.start_date as string
    );
    const today = new Date();
    const formattedCurrentDate = today.toISOString().split("T")[0];

    const dateColor = () => {
        if (schedule.start_date) {
            if (formattedDate > formattedCurrentDate) {
                return {
                    textColor: "#634D17",
                    bgColor: "#F7E5A480",
                };
            }
        }
        return {
            textColor: "#2F6217",
            bgColor: "#CCFAE9CC",
        };
    };

    return (
        <div>
            <Popover>
                <PopoverTrigger asChild>
                    <button
                        className={styles["schedule-item"]}
                        style={{
                            background: style.background,
                            cursor: "pointer",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: `${style.borderLeft}`,
                            }}
                            className="absolute -left-[1.5px] w-[3px] h-full top-0"
                        />
                        <div className="rbc-display_name">
                            {`${schedule.client.first_name} ${
                                schedule.client.last_name
                            } X  ${truncateString(
                                `${schedule.provider.first_name} ${schedule.provider.last_name}`,
                                8
                            )}`}
                        </div>
                        <div className="rbc-requested-service">
                            {makeStringFirstLetterCapital(
                                removeEnumUnderscore(schedule.service)
                            )}
                        </div>
                        <div className="rbc-wrapper">
                            <Tag
                                title={schedule.recurrence_rule as string}
                                textColor={
                                    getColorStylesForCalendarId(
                                        schedule.recurrence_rule as string
                                    ).textColor
                                }
                                bgColor={
                                    getColorStylesForCalendarId(
                                        schedule.recurrence_rule as string
                                    ).tagBgColor
                                }
                                className="text-[8px] font-medium py-8"
                            />
                            <Tag
                                title={formatDate(
                                    schedule.start_date as string
                                )}
                                textColor={dateColor().textColor}
                                bgColor={dateColor().bgColor}
                                className="text-[8px] font-medium py-8"
                            />
                            {schedule.client.insurance_provider_name ||
                            (schedule.client.self_pay &&
                                !showInsOverSelfPay) ? (
                                <Tag
                                    title={
                                        schedule.client.insurance_provider_name
                                            ? removeEnumUnderscore(
                                                  makeStringFirstLetterCapital(
                                                      schedule.client
                                                          .insurance_provider_name
                                                  )
                                              )
                                            : "SELF PAY"
                                    }
                                    textColor="#1A738D"
                                    bgColor="#ACECF5"
                                    className="text-[8px] font-medium py-8"
                                />
                            ) : null}

                            {schedule.coinsurance_amount && (
                                <Tag
                                    title={`COPAY: ${
                                        showMoneyInAppFormat(
                                            schedule.coinsurance_amount
                                        ) || ""
                                    }`}
                                    textColor="#165574"
                                    bgColor="#ACDEFA80"
                                    className="text-[8px] font-medium py-8"
                                />
                            )}

                            {schedule.note_status && (
                                <Tag
                                    title={`Note: ${removeEnumUnderscore(
                                        makeStringFirstLetterCapital(
                                            schedule.note_status
                                        )
                                    )}`}
                                    textColor={
                                        getNoteStatusTag(
                                            schedule.note_status as NoteStatus
                                        ).textColor
                                    }
                                    bgColor={
                                        getNoteStatusTag(
                                            schedule.note_status as NoteStatus
                                        ).bgColor
                                    }
                                    className="text-[8px] font-medium py-8"
                                />
                            )}

                            {schedule.invoice_status && (
                                <Tag
                                    title={`Bill: ${removeEnumUnderscore(
                                        makeStringFirstLetterCapital(
                                            schedule.invoice_status as BillStatus
                                        )
                                    )}`}
                                    textColor={
                                        getBillStatusTag(
                                            schedule.invoice_status as BillStatus
                                        ).textColor
                                    }
                                    bgColor={
                                        getBillStatusTag(
                                            schedule.invoice_status as BillStatus
                                        ).bgColor
                                    }
                                    className="text-[8px] font-medium py-8"
                                />
                            )}
                        </div>
                        <div className="flex justify-between mt-[12px]">
                            <div className="flex items-center text-x8 font-semibold text-gray">
                                {`${convertTimeTo12HoursFormat(
                                    `${schedule.session_start_hr}:${schedule.session_start_min}`
                                )?.toUpperCase()} - ${convertTimeTo12HoursFormat(
                                    `${schedule.session_end_hr}:${schedule.session_end_min}`
                                )?.toUpperCase()}`}
                            </div>
                        </div>
                    </button>
                </PopoverTrigger>
                <PopoverContent
                    align="center"
                    side="right"
                    sideOffset={16}
                    alignOffset={0}
                    className="h-auto shadow-none"
                >
                    <BillerScheduleDetails
                        schedule={schedule}
                        borderLeftColor={`${style.borderLeft}`}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}

export default function ScheduleWeekView() {
    const { practice } = usePractice();
    const [searchParams] = useSearchParams();
    const startOfWeek = searchParams.get("from_date");
    const clientFilters = searchParams.getAll("client_ids[]") || [];
    const providersFilters = searchParams.getAll("provider_ids[]") || [];
    const endOfWeek = searchParams.get("to_date");
    const loggedInProviderId = practice?.provider_id || "";

    const getProviderIds = () => {
        if (providersFilters.length > 0) {
            return providersFilters;
        }

        if (loggedInProviderId) {
            return [`${loggedInProviderId}`];
        }

        return [];
    };

    const { data, isLoading, error, isSuccess } = useFetchWeeklySchedule({
        client_ids: clientFilters,
        provider_ids: getProviderIds(),
        from_date: startOfWeek,
        to_date: endOfWeek,
    });

    const allSchedulesByDay: { [key: string]: IWeeklySchedule[] } = {
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thur: [],
        fri: [],
        sat: [],
    };

    const startOfWeekMoment = moment(startOfWeek, "YYYY-MM-DD");

    const daysOfWeekFormatted = Array.from({ length: 7 }).map((_, index) =>
        startOfWeekMoment.clone().add(index, "days").format("ddd MMM D")
    );

    const daysOfWeek = Object.keys(allSchedulesByDay);

    const seenSchedules = new Set();

    // show an empty calendar if there are no client or provider filters
    const finalData =
        clientFilters?.length === 0 && getProviderIds()?.length === 0
            ? []
            : data?.data;

    finalData?.forEach((s) => {
        const startDate = moment(s.start_date);
        const dayOfWeek = daysOfWeek[startDate.day()];

        const startTime = moment
            .utc() // Ensure UTC handling
            .set("hour", s.session_start_hr)
            .set("minute", s.session_start_min)
            .set("second", 0)
            .set("millisecond", 0)
            .local();

        const endTime = moment
            .utc() // Ensure UTC handling
            .set("hour", s.session_end_hr)
            .set("minute", s.session_end_min)
            .set("second", 0)
            .set("millisecond", 0)
            .local();

        const schedule = {
            ...s,
            start: startTime.toISOString(),
            end: endTime.toISOString(),
        };
        const scheduleKey = `${s.start_date}_${s.session_start_hr}_${s.session_start_min}_${s.session_end_hr}_${s.session_end_min}_${s.client.first_name}_${s.client.last_name}_${s.service}`;
        if (!seenSchedules.has(scheduleKey)) {
            allSchedulesByDay[dayOfWeek].push(schedule);
            seenSchedules.add(scheduleKey);
        }
    });

    const timeSlots = Array.from({ length: 24 }).map((_, index) => {
        const time = moment().startOf("day").add(index, "hours");
        return {
            label: time.format("LT"),
            value: time,
        };
    });

    return (
        <div>
            {isLoading && (
                <div className="pb-[72px]">
                    <Skeleton width="100%" height="200px" />
                </div>
            )}
            {error && (
                <ListState
                    isError
                    errorMsg={
                        error.response?.data.message ||
                        "Could not load user caseload at this time"
                    }
                    stateHelperText="Try reloading this page to solve this issue"
                    context="general"
                    cardClass="h-[200px]"
                />
            )}
            {data && isSuccess && (
                <div className="border border-strokedark rounded-r4">
                    <div className={styles["week-calendar"]}>
                        <div className={styles.time}>
                            <div />
                            {timeSlots.map((timeSlot, index) => (
                                <div
                                    key={`${index + 1}`}
                                    className={styles["single-time"]}
                                >
                                    {timeSlot.label}
                                </div>
                            ))}
                        </div>
                        <div className={styles.days}>
                            {daysOfWeek.map((day, dayIndex) => (
                                <div key={day}>
                                    <div className={styles.weekdays}>
                                        {daysOfWeekFormatted[dayIndex]}
                                    </div>
                                    <div className={styles["single-day"]}>
                                        {timeSlots.map((timeSlot, index) => (
                                            <div
                                                key={`${index + 1}`}
                                                className={
                                                    styles["schedule-wrap"]
                                                }
                                            >
                                                {allSchedulesByDay[day]
                                                    .filter((s) => {
                                                        const sessionStartTime =
                                                            moment(
                                                                s.start
                                                            ).local();

                                                        return (
                                                            sessionStartTime.hour() ===
                                                            index
                                                        );
                                                    })
                                                    .map((s) => (
                                                        <div
                                                            key={s.session_id}
                                                            style={{
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <SingleSchedule
                                                                schedule={s}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
